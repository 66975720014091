import React from 'react';
import { useCreateContext } from '../contexts/create-context';
import SEO from 'components/seo';

import { reducer } from '../contexts/app/app.reducer';
import { initialState } from '../contexts/app/app.reducer';

// Load other package css file
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';

import FeatureSection from '../feature-section/feature-section';
import Banner from '../banner-section/Banner';
import Faq from '../faq-section/faq';
import Layout from '../components/layout';
import mixpanel from 'mixpanel-browser';

// Set this to a unique identifier for the user performing the event.
// eg: their ID in your database or their email address.

// Track an event. It can be anything, but in this example, we're tracking a Signed Up event.
// Include a property about the signup, like the Signup Type

export default function InstagramPage() {
  const [, , StickyProvider] = useCreateContext(initialState, reducer);

  return (
    <StickyProvider>
      <Layout>
        <SEO title="Gymbase | Social workout tracking" />
        <Banner context={'Instagram'} />
        <FeatureSection />
        <Faq />
      </Layout>
    </StickyProvider>
  );
}
